import React from "react";
import Header from "../../Components/Header/QeteroHeader";
import Footer from "../Footer/SimpleFooter";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const AboutUs = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  return (
    <div className="">
      <Header />
      <div className="container mx-auto py-16 px-6 md:px-12">
        <h1 className="text-3xl md:text-5xl font-extrabold text-center mb-8 md:mb-12 text-gray-800">
          {t('whoWeAre')} {/* Translate: Who We Are */}
        </h1>
        <p className="text-base md:text-lg text-center mb-12 md:mb-16 text-gray-600 max-w-3xl mx-auto">
          {t('aboutUsDescription')} {/* Translate: Welcome to Qetero... */}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
          <div className="text-center p-6 md:p-8 bg-white shadow-xl rounded-lg transform transition-transform hover:-translate-y-4">
            <div className="mb-4 md:mb-6">
              <svg
                className="w-12 md:w-16 h-12 md:h-16 mx-auto text-indigo-500 animate-bounce"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-4 text-indigo-700">
              {t('mission')} {/* Translate: Mission */}
            </h2>
            <p className="text-sm md:text-base text-gray-600 leading-relaxed">
              {t('missionDescription')} {/* Translate: At Qetero, our mission is... */}
            </p>
          </div>

          <div className="text-center p-6 md:p-8 bg-white shadow-xl rounded-lg transform transition-transform hover:-translate-y-4">
            <div className="mb-4 md:mb-6">
              <svg
                className="w-12 md:w-16 h-12 md:h-16 mx-auto text-green-500 animate-spin"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-4 text-green-700">
              {t('vision')} {/* Translate: Vision */}
            </h2>
            <p className="text-sm md:text-base text-gray-600 leading-relaxed">
              {t('visionDescription')} {/* Translate: Our vision is to become... */}
            </p>
          </div>

          <div className="text-center p-6 md:p-8 bg-white shadow-xl rounded-lg transform transition-transform hover:-translate-y-4">
            <div className="mb-4 md:mb-6">
              <svg
                className="w-12 md:w-16 h-12 md:h-16 mx-auto text-red-500 animate-pulse"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2a10 10 0 11-10 10A10 10 0 0112 2zm1 15h-2v-2h2zm0-4h-2V7h2z"></path>
              </svg>
            </div>
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-4 text-red-700">
              {t('coreValues')} {/* Translate: Core Values */}
            </h2>
            <p className="text-sm md:text-base text-gray-600 leading-relaxed">
              {t('coreValuesDescription')} {/* Translate: We prioritize the needs... */}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import ServiceSelection from './ServiceSelection';
import BranchSelection from './BranchSelection';
import DateSelection from './DateSelection';
import TimeSlotSelection from './TimeSlotSelection';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import i18n from '../../i18n';
import { GrAnnounce } from "react-icons/gr";

import { convertToAmPm, convertToEthiopianDate, convertToEthiopianLocalTime, formatTime } from '../../utils/convertTime';
import { jwtDecode } from 'jwt-decode';

const ConfirmationModal = ({ selectedBranchName, selectedServiceName, isLoading, selectedDate, selectedTimeSlot, totalFee = "2 ETB", onClose, onConfirm }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-2 z-50">
    <div className="bg-white border border-gray-400 p-6 md:p-12 rounded-lg shadow-2xl w-full max-w-md md:max-w-lg transform transition-all duration-300">
      <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-8 text-start">{i18n.t('confirm_booking')}</h2>

      <div className="text-sm md:text-lg text-gray-700">
        <div className="flex justify-between gap-4 mb-4">
          <span className="font-normal">{i18n.t('branch')}:</span>
          <span className="font-semibold">{i18n.t(selectedBranchName)}</span>
        </div>
        <div className="flex justify-between gap-4 pb-4 mb-4 border-b border-gray-300">
          <span className="font-normal">{i18n.t('service')}:</span>
          <span className="font-semibold">{i18n.t(selectedServiceName)}</span>
        </div>


        <div className="flex justify-between gap-4 mb-4">
          <span className="font-normal">{i18n.t('bookingDate')}:</span>
          <div className="flex flex-col justify-end items-end">
            <span className="font-semibold text-xs md:text-sm">{convertToEthiopianDate(selectedDate)} </span>
            <span className="font-semibold  text-xs md:text-sm">{formatTime(selectedDate)} </span>
          </div>
        </div>
        <div className="flex justify-between gap-4 mb-4">
          <span className="font-normal">{i18n.t('bookingTime')}</span>
          <div className="flex flex-col justify-end items-end">
            <span className="font-semibold text-xs md:text-sm"> {convertToEthiopianLocalTime(selectedTimeSlot?.time_from)}{`( ${convertToAmPm(selectedTimeSlot?.time_from)} )`}</span>
          </div>
        </div>
      </div>

      <div className="mt-8 flex  justify-between align-middle bg-blue-100 p-4 rounded-lg text-lg md:text-xl pt-2">
        <div className="flex flex-col">
          <p className="text-sm font-normal text-gray-500 ">{i18n.t('payment_summary')}</p>
          <span className="font-medium text-gray-600">{i18n.t('serviceFee')}:</span>
        </div>
        <span className="text-green-600 self-end font-black">{totalFee}</span>
      </div>
      <div className='flex flex-col align-middle text-base justify-start'>
         <img src={'./TeleBirr-Logo.svg'} alt="Telebirr Logo" className="w-24 h-16 contain-content self-center" />
         <h3 className=' text-center text-lg  text-gray-800  font-semibold'>{i18n.t("pay_with_telebirr")}</h3>
      </div>
      <div className="flex justify-around items-center space-x-4 mt-4">
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 text-base md:text-lg py-2 px-5 rounded-lg shadow hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none transition duration-300"
            >
              {i18n.t('cancel')}
            </button>
            <button
              onClick={onConfirm}
              className="flex items-center bg-primary font-bold border border-primary text-white text-sm md:text-lg py-2 px-5 rounded-lg shadow hover:bg-black-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-300"
            >

              {isLoading ? i18n.t('loading') : i18n.t('confirmAndPay')}
            </button>
          </div>
    </div>
  </div>
);

const Booking = () => {
  const fixedAmount = "2";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState();
  const [selectedServiceOriginal, setSelectedServiceOriginal] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { user, token,setToken } = useContext(AuthContext);

  const announcementMessage = "DARS branches will soon offer an online appointment booking system to streamline visits, reduce wait times, and enhance your experience. Stay tuned for updates!";
  const news = "Exciting news!";

  const stepTitles = ['selectBranch', 'selectService', 'selectDate', 'selectTimeSlot'];
  console.log("token", user)
  const nextStep = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
    if (step === 1) {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp < currentTime) {
            localStorage.removeItem('token');
            setToken(null);
            navigate('/Login');
            return;
          } else {
            setStep(2);
            return;
          }
        } catch (error) {
          console.error("Invalid token:", error);
          navigate('/Login');
          return;
        }
      } else {
        // No token, redirect to login
        navigate('/Login');
      }
    }
  
    // Proceed with the remaining steps if the token is valid
    if (step === 2) setStep(3);
    if (step === 3) setStep(4);
    if (step === 4) setShowModal(true);
  };

  const initiatePayment = async (appointmentId) => {
    try {
      setIsLoading(true);
      //If C2B
      if (window.consumerapp === undefined || window.consumerapp === null) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/payments`, {
          appointment_id: appointmentId,
          type: "service Fee",
          amount: fixedAmount.toString(),
          title: "serviceFee_" + fixedAmount,
          paymentType: "C2B"
        });

        const paymentUrl = response.data.url;
        if (paymentUrl) {
          window.location.href = paymentUrl.trim();
        }


      }
      else { //If Telebirr Superapp 
        window.handleinitDataCallback = function () {
          window.location.href = window.location.origin;
        };
        if (!fixedAmount) {
          return;
        }
        setIsLoading(true);
        window
          .fetch(`${process.env.REACT_APP_API_URL}/v1/payments`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              appointment_id: appointmentId,
              type: "service Fee",
              amount: fixedAmount.toString(),
              title: "serviceFee_" + fixedAmount,
              paymentType: "InApp"
            }),
          })
          .then((res) => {
            res
              .text()
              .then((rawRequest) => {
                //   console.log(rawRequest.trim());
                let obj = JSON.stringify({
                  functionName: "js_fun_start_pay",
                  params: {
                    rawRequest: rawRequest.trim(),
                    functionCallBackName: "handleinitDataCallback",
                  },
                });

                if (typeof rawRequest === undefined || rawRequest === null) return;
                if (window.consumerapp === undefined || window.consumerapp === null) {
                  console.log("This is not opened in app!");
                  return;
                }
                window.consumerapp.evaluate(obj);
              })
              .catch((error) => {
                console.log("error occur", error);
              })
              .finally(() => { });
          })
          .finally(() => {
            //loading.hide();
            setIsLoading(false);
          });
      }

    } catch (error) {
      console.error("Error initiating payment:", error.message);
    } finally {
      setIsLoading(false);
    }
  };
 

  const addAppointmentAndPay = async () => {
    setIsLoading(true);
    const [hours, minutes] = selectedTimeSlot.time_from.split(':');
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);

    const appointmentData = {
      appointment_time: date.toISOString(),
      reschedule_count: 0,
      appointment_through: "self",
      branch_service_id: selectedService,
      appointment_date: date.toISOString().split("T")[0],
      appointment_duration_id: selectedTimeSlot.duration_id,
      created_by: user?.id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/add_appointment`,
        appointmentData
      );

      if (response.data?.status === "success") {
        const appointmentId = response?.data?.data?.data?.id;
        await initiatePayment(appointmentId);
      }
    } catch (err) {
      console.error("Error creating appointment:", err);
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex pt-4 bg-gray-100 justify-center align-middle self-center">
        {stepTitles.map((title, index) => (
          <div key={index} className="flex items-center mr-4 mb-2">
            <div className={`w-16 h-2 rounded-r-md flex items-center justify-center ${step >= index + 1 ? "bg-pink-600" : "bg-gray-700"}`}></div>
          </div>
        ))}
      </div>

      {step === 1 && (
        <div className="p-2 m-2 border border-gray-300 rounded-lg bg-yellow-200 text-center mb-4">
          <h3 className="text-xl font-semibold mb-2">{t(news)}</h3>
          <p className="text-sm text-gray-700">{t(announcementMessage)}</p>
        </div>
      )}

      {step === 1 && (
        <BranchSelection
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          setSelectedBranchName={setSelectedBranchName}
          nextStep={nextStep}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {step === 2 && (
        <ServiceSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedServiceOriginal={selectedServiceOriginal}
          setSelectedService={setSelectedService}
          setSelectedServiceOriginal={setSelectedServiceOriginal}
          setSelectedServiceName={setSelectedServiceName}
          selectedAddress={selectedAddress}
          nextStep={nextStep}
          backStep={() => setStep(1)}
          selectedBranchName={selectedBranchName}
        />
      )}
      {step === 3 && (
        <DateSelection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          setSelectedDate={setSelectedDate}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(2)}
          nextStep={nextStep}
        />
      )}
      {step === 4 && (
        <TimeSlotSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(3)}
          nextStep={nextStep}
        />
      )}

      {showModal && (
        <ConfirmationModal
          selectedBranchName={selectedBranchName}
          selectedServiceName={selectedServiceName}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          onClose={() => setShowModal(false)}
          onConfirm={addAppointmentAndPay}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Booking;

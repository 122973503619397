import React, { useState, useEffect, useContext } from "react";
import "../../index.css";
import {
  UserOutlined,
  BranchesOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import QeteroFooter from "../Footer/QeteroFooter";
import QeteroHeader from "../Header/QeteroHeader";
import QeteroService from "./QeteroService";
import languages from "../language.json"; // Import the language JSON
import { useLanguage } from "../../contexts/llanguageContext";
function LandingPage() {
  //const [language, setLanguage] = useState("en"); // Default language
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []); // Remove `language` dependency here

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem("language", selectedLanguage);
    setLanguage(selectedLanguage);
    // Here you would typically trigger a language change in your app's localization
  };
  

  return (
    <div className="bg-gray-50">
      <QeteroHeader />
      <div className="relative">
        <div className="absolute top-4 left-4 z-10"></div>
      </div>

      <div className="pt-20 px-4 py-10 bg-gradient-to-r relative">
        <div className="flex flex-col md:flex-row mt-20 md:ml-28 md:max-w-[740px]">
          <div className="text-4xl font-bold md:text-5xl">
            <p className="leading-relaxed">
              {languages[language].landingPageText1}
              <br />
              {languages[language].landingPageText2}
              <br />
              {languages[language].landingPageText3}
            </p>
          </div>
        </div>
        <img
          src="./people-using-online-appointment-concept-600nw-2094110803-removebg-preview.png"
          alt="Icon"
          className="absolute top-1/2 right-48 transform -translate-y-1/2 object-cover mt-20 hidden md:block"
        />
      </div>

      <div className="text-center mt-32">
        <h2 className="text-3xl font-semibold text-gray-900 md:text-4xl">
          {languages[language].ourPartners}
        </h2>
        <div className="mt-12 flex flex-wrap justify-center">
          <QeteroService
            title="Dars"
            description="Vehicle sales, residence sales, business sales, other property sales, vehicle spare parts sales, lease sales"
            className="w-full md:w-1/3 lg:w-1/4"
          />
        </div>
      </div>

      <div className="py-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-semibold text-gray-900">
            {languages[language].latestNews}
          </h2>
          <p className="text-md md:text-lg text-gray-700 mt-2">
            {languages[language].newsIntro}
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-8 px-4 text-sm">
          {[
            {
              img: "./qetero-meeting.jpg",
              title: "DARS and qetero Agreement",
              description:
                "DARS, a leading governmental agency committed to enhancing public services, has entered into a strategic partnership with Qetero, an innovative online appointment booking system. This collaboration aims to streamline and modernize the appointment scheduling process for DARS's wide range of services, making it more convenient and efficient for citizens..",
            },
            {
              img: "./qetero-survey.jpg",
              title: "Qetero On-Site Research",
              description:
                "In a proactive step to enhance its partnership with DARS, the Qetero team recently conducted an extensive on-site research visit to understand the current service delivery methods employed by the agency. This research is a part of Qetero's commitment to tailor its appointment booking system to meet the unique needs of DARS and its clients.",
            },
            {
              img: "./dars-trust.jpg",
              title: "Trustworthy Services",
              description:
                "At Qetero, trust and responsibility are at the core of everything we do. We understand that when it comes to managing appointments and personal information, our clients need a service they can depend on. That’s why we are committed to delivering a platform that is not only reliable but also built with the highest standards of security and integrity.",
            },
          ].map((news, index) => (
            <div
              key={index}
              className="bg-white hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 w-full md:w-80"
            >
              <img
                className="w-full h-48 object-cover"
                src={news.img}
                alt={news.title}
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {news.title}
                </h3>
                <p className="text-base text-gray-600">{news.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <QeteroFooter />
    </div>
  );
}

export default LandingPage;

import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState, useCallback, useContext } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the hook for i18n
import "../../index.css";
import SimpleFooter from "../Footer/SimpleFooter";
import Header from "../Header/QeteroHeader";
import CancelModal from "./CancelModal";
import FreeModal from "./FreeModal";
import PaymentModal from "./Paymentmodal";
import { AuthContext } from "../../contexts/authContext";
function Confirmation() {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [iscancelmodal, setIscancelmodalopen] = useState(false);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use `useTranslation` hook for i18n
  const [id, setId] = useState("");
  const {user, token} = useContext(AuthContext)
  const data = {
    branch: searchParams.get("branch"),
    service: searchParams.get("service"),
    cnr: searchParams.get("cnr"),
    appointment_time: new Date(searchParams.get("appointment_time")),
    id: searchParams.get("id"),
  };

  const handlePayNowClick = () => {
    setIsPaymentModalOpen(true);
  };

  const handelcancelclick = () => {
    setIscancelmodalopen(true);
  };

  const handleCancelclose = () => {
    setIscancelmodalopen(false);
  };

  const handleCloseModal = () => {
    setIsPaymentModalOpen(false);
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  // Helper function to format the appointment time
  const formatAppointmentTime = (appointmentTime) => {
    const date = new Date(appointmentTime);
    date.setHours(date.getHours() - 6); // Deduct 6 hours

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[date.getDay()];

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${dayName}, ${year}-${month}-${day} ${hours}:${minutes}`;
  };


  return (
    <div>
      <Header />
      <div className="flex flex-col text-stone-900 mt-12">
        <div className="flex justify-center items-center w-screen mt-4 md:mt-10">
          <div className="self-end text-3xl md:text-5xl font-bold text-center">
            <span className="text-indigo-500 ">{t('save_time_and_book_now')}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-4 md:mt-10 w-full text-2xl font-semibold">
        <div className="mt-4 md:mt-10 md:w-1/2 h-full border rounded-md p-6 md:mr-20">
          <p className="text-2xl font-semibold">{t('confirm_appointment')}</p>
          <div className="mt-8">
            <div className="flex justify-between mb-6 text-sm md:text-2xl">
              <span className="font-light">{t('booked_by')}: </span>
              <span>{user?.firstname} {user?.lastname}</span>
            </div>
            <div className="flex justify-between mb-6 text-sm md:text-2xl">
              <span className="font-light">{t('service_type')}: </span>
              <span>{t(data.service)}</span>
            </div>
            <div className="flex justify-between mb-6 text-sm md:text-2xl">
              <span className="font-light">{t('scheduled_for')}</span>
              <span>{formatAppointmentTime(data.appointment_time)}</span> {/* Formatted appointment time */}
            </div>
            <div className="flex justify-between mb-6 text-sm md:text-2xl">
              <span className="font-light">{t('location')}</span>
              <span>{t(data.branch)}</span>
            </div>
          </div>
        </div>
        <div className="mt-4 md:mt-10 border rounded-md p-6">
          <p className="text-2xl font-semibold">{t('payment_summary')}</p>
          <div className="mt-8">
            <div className="flex justify-between mb-4 text-sm md:text-2xl">
              <span className="font-light">{t('total_appointment_fee')}</span>
              <span>{(window.consumerapp === undefined || window.consumerapp === null) ? "0.00 ETB" : "2 ETB"}</span>
            </div>
            <div className="flex flex-col items-center ">
              <button
                className="bg-red-400 text-white text-xl py-2 px-4 rounded mb-2 mt-4 w-full"
                onClick={handelcancelclick}
              >
                {t('request_cancellation')}
              </button>
              <button
                className="bg-indigo-500 text-white text-xl py-2 px-4 rounded w-full"
                onClick={handlePayNowClick}
              >
                {t('book_now')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CancelModal isOpen={iscancelmodal} onClose={handleCancelclose} />
      {/* { (window.consumerapp === undefined || window.consumerapp === null) ?
        <FreeModal
          isOpen={isPaymentModalOpen}
          onClose={handleCloseModal}
          appointmentId={data?.id}
          data={data}
        />
        :
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={handleCloseModal}
          appointmentId={data.id}
        />
      } */}
      <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={handleCloseModal}
          appointmentId={data.id}
        />
      <SimpleFooter />
    </div>
  );
}

export default Confirmation;

import React, { useState } from "react";
import { DayPicker } from 'react-day-picker';
import { addDays, subMonths, addMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';
import { convertToEthiopianDate, formatTime } from '../../utils/convertTime';
import { FaBuildingColumns } from "react-icons/fa6";

const DateSelection = ({ selectedDate, setSelectedDate, selectedBranchName, selectedServiceName, selectedService, nextStep, backStep }) => {
  const { t } = useTranslation();
  const [tempDate, setTempDate] = useState(null); // Temporarily selected date
  const today = new Date();
  const tenDaysLater = addDays(today, 10); // Calculate 10 days from today

  const handleDateClick = (date) => {
    setTempDate(date);
    setSelectedDate(date); // Confirm the date selection
  };

  // Define the limits for month navigation
  const fromMonth = subMonths(today, 0);  // One month backward from today
  const toMonth = addMonths(today, 1);    // One month forward from today

  return (
    <div className="">
      <div className="flex px-2 justify-center align-middle text-center mt-2 bg-white border-gray-200 border rounded-xl py-2">
        <FaBuildingColumns className="w-10 h-10 text-gray-500 mr-4" />
        <div className="flex flex-col justify-start">
          <h2 className="font-semibold ml-2 line-clamp-2 text-lg text-gray-800">
            {t(selectedBranchName)} {t("branch")}
          </h2>
          <p className="font-normal ml-2 line-clamp-2 text-sm text-gray-800">
             {t("service")}: {selectedServiceName} 
          </p>
        </div>
      </div>
      <div className="bg-white border p-2 m-2 rounded-lg border-gray-300 text-gray-900 text-sm">
        <h2 className="text-pretty text-sm md:text-lg pb-2 text-gray-600 font-semibold">
          {t("selectDate")}
        </h2>

        <DayPicker
          mode="single"
          selected={tempDate || selectedDate}
          onSelect={handleDateClick}
          startMonth={fromMonth}  // Start navigation from one month before today
          endMonth={toMonth}      // End navigation one month after today
          disabled={[
            { before: today },               // Disable past dates
            { after: tenDaysLater },          // Disable dates beyond 10 days from today
            { dayOfWeek: [0] },               // Disable Sundays
          ]}
          modifiersClassNames={{
            selected: 'bg-secondary text-white rounded-md shadow-sm',
            default: "bg-white rounded-md shadow-sm",
            today: 'bg-gray-200 rounded-md shadow-sm ',
            disabled: 'text-gray-300 cursor-not-allowed shadow-sm',
            future: "text-gray-500 cursor-not-allowed shadow-md",
          }}
          className="border-gray-300 p-2 border text-gray-800 text-sm rounded-md transition-all duration-300 ease-in-out"
        />
        
        {tempDate && (
          <div className="mt-4 bg-gray-100 p-4 rounded-lg border border-gray-400">
            <p className="text-gray-600 mb-2 font-semibold">{t('selectedDate')}: {formatTime(tempDate)}</p>
            <p className="text-gray-600 font-semibold">{t('ethiopianDate')}: {convertToEthiopianDate(tempDate)}</p>
          </div>
        )}
        
        <div className="flex w-full justify-around mt-4">
          <button 
            onClick={backStep} 
            className="bg-gray-400 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-500 transition duration-300 ease-in-out"
          >
            {t('back')}
          </button>
          {tempDate && (<button
            onClick={nextStep}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-500 transition duration-300 ease-in-out"
          >
            {t('next')}
          </button>)}
        </div>
      </div>
    </div>
  );
};

export default DateSelection;

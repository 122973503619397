import React from "react";
import Header from "../../Components/Header/QeteroHeader";
import { useTranslation } from "react-i18next";

const index = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      {/* <div className="container mx-auto p-4"> */}
      <div className="flex flex-row  flex-1 align-middle justify-around px-1 py-1 from-primary to-blue-500 bg-gradient-to-r rounded-xl text-stone-900 ">
        <div className="flex flex-col justify-start text-2xl w-1/2 p-2 md:text-3xl font-semibold text-white">
          <div className="font-bold  text-xl md:text-3xl mb-2 text-white">
            {t("selam")}
          </div>
          <p className="justify-center self-center ml-2 text-xs md:text-xl m-3 text-gray-300 ">
            {t(
              "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com"
            )}
          </p>
        </div>
        <div className="flex justify-end w-1/2">
          <img
            src="/Dars-Logo.png"
            alt="Qetero"
            className="w-28 h-28 md:w-32 md:h-32 self-center z-0 contain-content"
          />
        </div>
      </div>
      {/* </div> */}
      <div className="container mx-auto p-10">
        <h1 className="text-3xl font-bold mb-5 text-center">
          {t("easyStepsToRegister")}
        </h1>

        <div className="grid md:grid-cols-1 max-w-7xl gap-6 m-5">
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <h4 className="text-xl font-semibold text-center m-3">
              {t("step")} 1 :{t("selectBranchFromOptions")}
            </h4>
            <img
              src={require(`../assets/step1.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step One`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 2 :{t("enterPhonenumberAndGetOTP")}
            </p>
            <img
              src={require(`../assets/step2.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Two`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 3 :{t("verifyAndSelectService")}
            </p>
            <img
              src={require(`../assets/step3.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Three`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 4 :{t("selectDateOfAppointment")}
            </p>
            <img
              src={require(`../assets/step4.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Four`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 5 :{t("chooseTimeSlot")}
            </p>
            <img
              src={require(`../assets/step5.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Five`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 6:{t("clickOnBookAppointment")}
            </p>
            <img
              src={require(`../assets/step6.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Six`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-2xl rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 7 :{t("confirmAppointmentAndConfirm")}
            </p>
            <img
              src={require(`../assets/step7.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Seven`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
          <div className="bg-white shadow-lg rounded-lg p-5 flex flex-col items-center">
            <p className="text-xl font-semibold m-3 text-center">
              {t("step")} 8 :{t("enterTelebirrPinAndComplete")}
            </p>
            <img
              src={require(`../assets/step8.png`)} // Assuming images are placed in 'src/assets' folder
              alt={`Step Eight`}
              className="max-w-full max-h-96 object-cover mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CancelModal = ({ isOpen, onClose }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigation = useNavigate()
  const handleConfirm = () => {
    setIsSuccessModalOpen(true);
    navigation('/')
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  const handleCancelModalClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  return (
    <>
      {isOpen && !isSuccessModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-8 z-10">
            <p className="text-xl font-semibold mb-4">
              Are you sure you want to cancel the appointment?
            </p>
            <div className="flex justify-center">
              <button
                className="bg-indigo-500 text-white text-xl py-2 px-4 rounded mr-4"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="bg-gray-400 text-white text-xl py-2 px-4 rounded"
                onClick={handleCancelModalClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {isSuccessModalOpen && <SuccessModal onClose={handleSuccessModalClose} />}
    </>
  );
};

const SuccessModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg p-8 z-10">
        <p className="text-xl font-semibold mb-4">
          Appointment Canceled Successfully!
        </p>
        <div className="flex justify-center">
          <button
            className="bg-indigo-500 text-white text-xl py-2 px-4 rounded"
            onClick={onClose}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landingpage from "./Components/LandingPage/Landingpage";
import Appointment from "./Components/Appointment/Appointment";
import Confirmation from "./Components/Appointment/Confirmation";
import SuccessMessage from "./Components/Commons/SuccessMessage";
import AboutUs from "./Components/Aboutus/Aboutus";
import ContactUs from "./Components/Contactus/Contactus";
import Profile from "./Components/Profile/Profile";
import SignUp from "./Components/SignUp/SignUp";
import Login from "./Components/LogIn/Login";
import PIN from "./Components/LogIn/PIN";
import ForgetPIN from "./Components/LogIn/ForgetPIN";
import PINReset from "./Components/LogIn/PINReset";
import OTPForm from "./Components/LogIn/OtpForm";
import QeteroLandingPage from "./Components/LandingPage/QeteroLandingPage";
import OtpFormSignUp from "./Components/LogIn/OtpFormSignUp";
import { AuthProvider } from "./contexts/authContext";
import BookingHistory from "./Components/Appointment/BookingHistory";
import VConsole from 'vconsole';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HelpCenter from "./Components/HelpCenter"


const queryClient = new QueryClient();

function App() {
  if(window.consumerapp) {
    const vConsole = new VConsole();
  }
  
  return (
    <div className="bg-gray-100 ">
    <div className="container mx-auto">
    <AuthProvider>
      <BrowserRouter>
        <Routes>
        <Route path="/" 
          element={ 
             <QueryClientProvider client={queryClient}>
              <Appointment /> 
          </QueryClientProvider>} />
          <Route path="/Landingpage" element={<Landingpage />} />
          <Route path="/Home" element={<QeteroLandingPage />} />

          <Route path="/Landingpage/Appointment" element={<Appointment />} />
          <Route path="/Confirmation" element={<Confirmation />} />
          <Route path="/Aboutus" element={<AboutUs />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/Help" element={<HelpCenter />} />
          <Route path="/BookingHistory" element={
                         <QueryClientProvider client={queryClient}>
                            <BookingHistory />
                          </QueryClientProvider>} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/otp" element={<OTPForm />} />
          <Route path="/OtpSignUp" element={<OtpFormSignUp />} />
          <Route path="/PIN" element={<PIN />} />
          <Route path="/ForgetPIN" element={<ForgetPIN />} />
          <Route path="/PINReset" element={<PINReset />} />
          <Route path="/Success" element={<QueryClientProvider client={queryClient}>
          <SuccessMessage />
          </QueryClientProvider>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </div>
    </div>
  );
}

export default App;

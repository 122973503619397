import React, { createContext, useContext, useState } from 'react';

// Create a context for language
const LanguageContext = createContext();

// Hook to use the language context
export const useLanguage = () => useContext(LanguageContext);

// Provider to wrap the app and provide language functionality
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language is 'en'

  // Function to change the language
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

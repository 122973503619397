import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import TermsAndConditions from "../LogIn/TermsAndConditionsModal";
import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

const SignUp = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { mobile } = location.state || {};
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, setUser } = useContext(AuthContext);
  const [agreementToTermsAndConditions, setAgreementToTermsAndConditions] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [termsAndConditionsClicked, setTermsAndConditionsClicked] = useState(0);

  const handleCheckBox = (e) => {
    setAgreementToTermsAndConditions(e.target.checked);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  console.log("the value of checkbox: ", agreementToTermsAndConditions);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setError("");
    setIsLoading(true);

    if (firstName !== "" && lastName !== "") {
      if (agreementToTermsAndConditions) {
        // if (termsAndConditionsClicked != 0) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/v1/auth/signup`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                mobile: `+251${mobile}`,
                firstname: firstName,
                lastname: lastName,
                password,
              }),
            }
          );

          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setToken(data);
            setUser(data);
            localStorage.setItem("token", data);
            const decodedData = jwtDecode(data);
            setUser(decodedData);
            navigate("/");
          } else {
            throw new Error("Failed to register");
          }
        } catch (error) {
          console.error("Error registering:", error);
          setError(error.message || "Failed to register. Please try again.");
        } finally {
          setIsLoading(false);
        }
        // } else {
        //   setError(`${t("readTermsAndConditions")}`);
        //   setIsLoading(false)
        // }
      } else {
        setError(`${t("userAgreementToTerms")}`);
        setIsLoading(false);
      }
    } else {
      setError(`${t("allFieldsAreReqired")}`);
      setIsLoading(false);
    }
  };

  const handleTermsAndConditionsClicker = () => {
    setIsModalOpen(true);
    setTermsAndConditionsClicked(1);
  };

  return (
    <div>
      <header className="top-0 align-middle  left-0 w-full bg-gray-100 z-50 mb-4">
        <div className="flex justify-between items-center  mx-auto px-4 py-2">
          {/* Menu Toggle Button (Mobile) */}

          {/* Logo */}
          <div className="ml-4 flex items-center">
            <Link to="/">
              <img
                loading="lazy"
                src="./qetero_logo_text_3.svg"
                className="w-18 h-10 "
                alt="DARS Logo"
              />
            </Link>
          </div>
          <div>
            <img className="w-8 h-8 mr-2" src="/icon.png" alt="logo" />
          </div>
        </div>
      </header>
      <div className="absolute right-5 lg:right-14 inline-block text-left z-50">
        <button
          onClick={toggleLanguageDropdown}
          className="flex items-center px-2 py-1 rounded-full bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        >
          <MdOutlineLanguage className="text-white w-4 h-4 mr-2" />
          <span className="text-white text-xs md:text-sm font-medium">
            {i18n.language === "en" ? "English" : "አማርኛ"}
          </span>
          <IoIosArrowDown className="text-white w-4 h-4 mr-2" />
        </button>

        {languageDropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                onClick={() => {
                  i18n.changeLanguage("en");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                English
              </button>
              <button
                onClick={() => {
                  i18n.changeLanguage("am");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                አማርኛ
              </button>
            </div>
          </div>
        )}
      </div>
      <section className="flex flex-1 h-screen bg-slate-50 justify-center">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <form
            className="max-w-sm bg-white flex flex-col justify-center text-center mx-auto p-12 rounded-lg"
            onSubmit={(e) => preventDefault()}
          >
            <header className="mb-8">
              <h1 className="text-2xl font-bold mb-1">
                {t("createAnAccount")}
              </h1>
            </header>
            <label
              htmlFor="first-name-input"
              className="block mb-2 text-start text-sm font-medium text-gray-900"
            >
              {t("firstName")}:
            </label>
            <input
              type="text"
              id="first-name-input"
              className={`bg-gray-50 border ${
                error ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <label
              htmlFor="last-name-input"
              className="block mt-4 mb-2 text-start text-sm font-medium text-gray-900"
            >
              {t("lastName")}:
            </label>
            <input
              type="text"
              id="last-name-input"
              className={`bg-gray-50 border ${
                error ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <div className="flex inline-block m-2">
              <input
                type="checkbox"
                onChange={handleCheckBox}
                className="mt-4"
              />
              <label>
                <p
                  className="text-center mt-4 ml-3 text-green-600 cursor-pointer"
                  onClick={handleTermsAndConditionsClicker}
                >
                  {t("termsAndConditions")}
                </p>
              </label>
            </div>
            <br></br>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <button
              type="button"
              onClick={handleSubmit}
              className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              disabled={isLoading}
            >
              {isLoading ? "Registering..." : `${t("signUp")}`}
            </button>
          </form>
        </div>
        <TermsAndConditions
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          // from={"signup"}
          // action={onAgreeToTermsAndConditions}
        />
      </section>
    </div>
  );
};

export default SignUp;

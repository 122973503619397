import React from "react";
import Header from "../../Components/Header/QeteroHeader";
import Footer from "../Footer/SimpleFooter";
import {
  FaCommentAlt,
  FaPaperPlane,
  FaUser,
  FaEnvelope,
  FaPencilAlt,
} from "react-icons/fa";
const Contactus = () => {
  return (
    <div>
      <Header />
      <div className="flex justify-center mt-24 mx-4">
        <h1 className="text-3xl md:text-4xl font-bold p-0 text-center">
          ANY INQUIRIES?
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-6 md:px-16 mt-8 md:mt-12">
        <div className="bg-blue-800 p-4 md:p-8 rounded-lg shadow-md flex items-center justify-center">
          <img
            src="./qetero_logo_label.svg"
            alt="Cheche Logo"
            className="w-32 md:w-auto object-contain"
          />
        </div>

        <div className="col-span-1 md:col-span-2 bg-purple-50 p-4 md:p-8 rounded-lg shadow-md">
          <h3 className="text-2xl md:text-2xl font-bold mb-4">Get In Touch</h3>
          <p className="text-sm md:text-lg mb-6 md:mb-8 text-gray-900">
            Have questions or need assistance? Please fill out our inquiry form,
            and our team will get back to you as soon as possible. We're here to
            provide you with the information and support you need—your
            satisfaction is our priority!
          </p>

          <form
            action="https://formsubmit.co/fikeryibeltal31@gmail.com"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              openSuccessModal();
              e.target.submit();
            }}
            className="space-y-4 md:space-y-6"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  required
                  className="w-full pl-10 p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-300"
                />
              </div>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                  className="w-full pl-10 p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-300"
                />
              </div>
            </div>

            <div className="relative">
              <input
                type="text"
                name="subject"
                placeholder="Your Subject"
                required
                className="w-full pl-10 p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-300"
              />
            </div>

            <div className="relative">
              <textarea
                name="message"
                placeholder="Write Your Message"
                required
                className="w-full pl-10 p-2 md:p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-300"
                rows="4"
              ></textarea>
            </div>

            <input type="hidden" name="_next" value="./successmodal" />
            <input type="hidden" name="_captcha" value="false" />

            <button
              type="submit"
              className="w-full py-3 flex items-center justify-center bg-gradient-to-r from-blue-800 to-blue-900 text-white font-bold rounded-lg shadow-md hover:bg-gradient-to-l"
            >
              <FaPaperPlane className="mr-2" /> Send
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;

import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const TermsAndConditions = ({ isOpen, onClose, from, action }) => {
  const fixedAmount = 50;
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
        <div className="absolute bg-white rounded-lg shadow-lg p-4 w-full max-w-md">
          <button onClick={onClose} className="absolute right-8">
            <CloseOutlined className="text-gray-500 hover:text-gray-700" />
          </button>
          <h3 className="font-bold text-lg mb-2">{t("appointmentPolicy")}</h3>

          {/* Appointment Policy Section */}

          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <ul className="list-disc list-inside text-gray-700">
              <li style={{ fontSize: 12 }}>{t("appointmentsNonRefundable")}</li>
              <li style={{ fontSize: 12 }}>
                {t("appointmentsNonTransferable")}
              </li>
              <li style={{ fontSize: 12 }}>{t("validForSpecificService")}</li>
              <li style={{ fontSize: 12 }}>{t("oneServicePerAppointment")}</li>
              <li style={{ fontSize: 12 }}>{t("validIDRequired")}</li>
              <li style={{ fontSize: 12 }}>{t("mustArriveOnTime")}</li>
              <li style={{ fontSize: 12 }}>{t("latePolicy")}</li>
            </ul>
            {from == "signup" ? (
              <div>
                <p className="text-xs text-gray-600 mt-2">
                  {t("readAndAgree")}
                </p>
                <button onClick={action} className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                  {t("agree")}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;

import React, { useContext } from "react";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import Footer from "../Footer/SimpleFooter";
import Header from "../../Components/Header/QeteroHeader";
import { AuthContext } from "../../contexts/authContext";

function Appointment() {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center px-5 text-stone-900 mt-12">
        {/* Profile Section */}
        <div className="flex flex-col lg:flex-row w-full max-w-5xl mt-10 bg-white shadow-lg rounded-lg p-8">
          {/* Left Side - Profile Info */}
          <div className="lg:w-1/3 flex flex-col items-center border-b lg:border-b-0 lg:border-r pb-8 lg:pb-0 lg:pr-8">
            <div className="w-32 h-32 bg-gray-300 rounded-full mb-4 flex justify-center items-center overflow-hidden">
              {/* Placeholder for Profile Image */}
              <img
                src="https://via.placeholder.com/150"
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-3xl font-bold text-center text-gray-800">
              {user ? `${user.firstname} ${user.lastname}` : "Guest User"}
            </div>
            <p className="text-lg text-gray-600 mt-2 text-center">
              {user?.active ? "Active" : "Inactive"} User
            </p>
          </div>

          {/* Right Side - Profile Details */}
          <div className="lg:w-2/3 flex flex-col justify-center pl-0 lg:pl-10 pt-6 lg:pt-0">
            <div className="w-full max-w-lg bg-gray-50 shadow-sm rounded-lg p-8">
              <div className="mb-6">
                <label
                  htmlFor="fullName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Full Name:
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={user ? `${user.firstname} ${user.lastname}` : ""}
                  readOnly
                  className="border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Phone Number:
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={user ? user.mobile : ""}
                  readOnly
                  className="border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Appointment;

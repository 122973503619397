//simple footer react tailwind 
import React from 'react';

function SimpleFooter() {
  return (
    <footer className="bg-blue-800  rounded-t-xl text-white py-4 mt-40">
      <div className="container mx-auto text-center">
        <p>© 2024 Qetero. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default SimpleFooter;